import client from '../utils/axios';

const ARTICLE_ENDPOINT = process.env.REACT_APP_BASE_URL + '/contents/articles';
const POST_ENDPOINT = process.env.REACT_APP_BASE_URL + '/post/article';

export default {
  fetchTree: async (id, includeHistory) => {
    const { data } = await client.get(ARTICLE_ENDPOINT + '/publication/' + id + '/' + includeHistory);
    return data;
  },
  postPublish: async (params) => {
    const { data } = await client.post(ARTICLE_ENDPOINT + '/publication', params);
    return data;
  },
  recurPublish: async (params) => {
    const { data } = await client.post(ARTICLE_ENDPOINT + '/publication/recurring', params);
    return data;
  },
  search: async (params) => {
    const { data } = await client.get(ARTICLE_ENDPOINT + '/search', { params });
    return data;
  },
  schedule: async (params) => {
    const { data } = await client.get(ARTICLE_ENDPOINT + '/schedule', { params });
    return data;
  },
  summary: async (params) => {
    const { data } = await client.get(ARTICLE_ENDPOINT + '/summary', { params });
    return data;
  },
  getLinkViewPost: async (reference, channelInstanceId) => {
    const { data } = await client.get(POST_ENDPOINT + '/' + encodeURIComponent(reference) + '/' + channelInstanceId, {});
    return data;
  },
  deletePost: async (reference, channelInstanceId) => {
    const { data } = await client.delete(POST_ENDPOINT + '/' + encodeURIComponent(reference) + '/' + channelInstanceId, {});
    return data;
  },
  cancelArticle: async (id) => {
    const { data } = await client.delete(ARTICLE_ENDPOINT + '/publication/' + id, {});
    return data;
  },
  cloneArticle: async (id) => {
    const { data } = await client.post(ARTICLE_ENDPOINT + '/clone', {
      articleId: id
    });
    return data;
  },
  requestAuthorise: async (id) => {
    const { data } = await client.patch(ARTICLE_ENDPOINT + '/request-authorise/' + id);
    return data;
  },
  authorise: async (params) => {
    const { data } = await client.post(ARTICLE_ENDPOINT + '/authorise', params);
    return data;
  }
};
